import 'featherlight';
import 'selectize';
import 'tooltipster';
import '../sass/style.scss';
import './polyfills/array.prototype.findIndex';
import './polyfills/DOMTokenList.forEach';
import './common';
import './breakpoint';
import './header';
import './feedback';
import './treeCounter';
import './jquery.numscroller';

/*
const selectedValue = $('.language-select').val();
$('.language-select').selectize({
  hideSelected: true,
  items: [selectedValue],
  render: {
    item(data, escape) {
      return `<div class="language-item" data-link="${escape(data.link)}"><img class="language-icon" src="${escape(data.icon)}"><span class="language-name">${escape(data.text)}</span><span class="language-currency">${escape(data.currency)}</span></div>`;
    },
    option(data, escape) {
      return `<div class="language-item" data-link="${escape(data.link)}"><img class="language-icon" src="${escape(data.icon)}"><span class="language-name">${escape(data.text)}</span><span class="language-currency">${escape(data.currency)}</span></div>`;
    },
  },
  onChange(value) {
    const link = $(this.getItem(value)[0]).data('link');
    window.open(link, '_self');
  },
});


$('.language-select input').prop('readonly', true);
 */
